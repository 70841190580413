export const login = (state = { userRole: "admin" }, action) => {
  switch (action.type) {
    case "LOGIN_ADMIN": {
      return { ...state, ...action.payload, isAuthenticated: true };
    }
    case "LOGOUT_ADMIN": {
      return {
        ...state,
        user_data: null,
        isAuthenticated: false,
        auth_token: null,
        userRole: null,
      };
    }
    default: {
      return state;
    }
  }
};
