import { history } from "../../../history";

export const logoutAdmin = () => {
  return (dispatch) => {
    dispatch({ type: "LOGOUT_ADMIN", payload: {} });
    localStorage.removeItem("auth_token");
    localStorage.removeItem("user_data");
    alert("Logout Successfully !");
    history.push("/page/login");
  };
};
